import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from './Appbar';
import Footer from './Footer';
import Preloader from './Preloader';
import { useParams } from 'react-router-dom';
import './PickACard.css';


const PickACardPost = () => {
  const { index } = useParams();
  // const lang = localStorage.getItem('selectedLanguage');
  const lang = "mm";
  const [data, setData] = useState([]);
  const [type, setType] = useState([]);

  const [isLoading, setLoading] = useState(true);

  const gotoPickACardPostRes = (i) => {
    localStorage.setItem('pickAcard',i+1);
    window.location.href = "/home/pick-a-card-result/"+index;
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}pickacard.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result.card[index]);
        setType(result.card[index].type);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(true);
      }
    };
    fetchData();
  }, [lang, index]);

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        data && (
          <div className="uk-card uk-width-1-1@m" style={{ marginTop: "70px" }}>
            <div className="uk-card-header">
              <h3 className="uk-bold">{data.name}</h3>
              <h4>{data.desc}</h4>
              <div className="grid-container pickres">
              {Object.values(type).map((item, i) => (
                 <div className='grid-item'
                 key={i} onClick={() => gotoPickACardPostRes(i) } >
                  <img src={item.img} alt={item.title} />
                  <h5>{item.title}</h5>
                </div>
              ))}
            </div>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default function PickACardPostReading() {
  return (
    <div>
      <ResponsiveAppBar />
      <PickACardPost />
      <Footer/>
    </div>
  );
}
