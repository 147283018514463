import "./App.css";
import { BrowserRouter as Router, Route, Routes, Navigate  } from "react-router-dom";
import Login from "./Login";
// import Register from "./Register";
// import Reset from "./Reset";
import Home from "./Home";
import Dashboard from "./Dashboard";

import Astrology from "./Astrology";
import Astrologydetails from "./Astrologydetails";

import AngelNums from "./AngelNums";
import AngelNumsdetails from "./AngelNumsdetails";

import Mahabote from "./Mahabote";
import DailyMahabote from "./DailyMahabote";
import GeneralMahabote from "./GeneralMahabote";

import MonthlySpecial from "./MonthlySpecial";
import MonthlySpecialdetails from "./MonthlySpecialdetails";

import Numerology from "./Numerology";
import BirthdateNumerology from "./BirthdateNumerology";
import PersonalityNumerology from "./PersonalityNumerology";

import PickACard from "./PickACard";
import PickACardPost from "./PickACardPost";
import PickACardPostRes from "./PickACardPostRes";

import SpiritualAnimals from "./SpiritualAnimals";
import SpiritualAnimalsdetails from "./SpiritualAnimalsDetails";

import Tarot from "./Tarot";
import DailyTarot from "./DailyTarot";
import DailyTarotResult from "./DailyTarotResult";
import TarotcardMeaning from "./TarotcardMeaning";
import TarotcardMeaningDetails from "./TarotcardMeaningDetails";

import VideoReading from "./VideoReading";

import Zodiac from "./Zodiac";
import WeeklyZodiac from "./WeeklyZodiac";
import WesternZodiac from "./WesternZodiac";
import ZodiacCompat from "./ZodiacCompat";
import ChineseZodiac from "./ChineseZodiac";

import CookiePolicy from "./CookiePolicy";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsConditions from "./TermsConditions";
import { useEffect } from "react";


function App() {
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5046935516889578";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    return () => {
      // Cleanup the script if necessary
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="app">
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-5046935516889578"
        data-ad-slot="1234567890"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
      <Router>
        <Routes>
          <Route exact path="/login" element={<Login />} />
          {/* <Route exact path="/register" element={<Register />} />
          <Route exact path="/reset" element={<Reset />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route exact path="/dashboard" element={<Dashboard />} />

          <Route exact path="/home/angel numbers" element={<AngelNums />} />
          <Route exact path="/home/angelnumbersdetails/:index" element={<AngelNumsdetails />} />

          <Route exact path="/home/astrology" element={<Astrology />} />
          <Route exact path="/home/astrologydetails/:index" element={<Astrologydetails />} />

          <Route exact path="/home/monthly special dates" element={<MonthlySpecial />} />
          <Route exact path="/home/monthlyspecialdetails/:index" element={<MonthlySpecialdetails />} />

          <Route exact path="/home/mahabote" element={<Mahabote />} />
          <Route exact path="/home/mahabote/daily-mahabote" element={<DailyMahabote />} />
          <Route exact path="/home/mahabote/general-mahabote" element={<GeneralMahabote />} />

          <Route exact path="/home/numerology" element={<Numerology />} />
          <Route exact path="/home/numerology/birthdate-numerology" element={<BirthdateNumerology />} />
          <Route exact path="/home/numerology/personality-numerology" element={<PersonalityNumerology />} />

          <Route exact path="/home/pick a card" element={<PickACard />} />
          <Route exact path="/home/pick-a-card-post/:index" element={<PickACardPost />} />
          <Route exact path="/home/pick-a-card-result/:index" element={<PickACardPostRes />} />

          <Route exact path="/home/spiritual animals" element={<SpiritualAnimals />} />
          <Route exact path="/home/spiritualanimalsdetails/:index" element={<SpiritualAnimalsdetails />} />

          <Route exact path="/home/tarot" element={<Tarot />} />
          <Route exact path="/home/tarot/daily-tarot" element={<DailyTarot />} />
          <Route exact path="/home/tarot/dailytarot-result" element={<DailyTarotResultWithRedirect />} />
          <Route exact path="/home/tarot/tarot-cards-meaning" element={<TarotcardMeaning />} />
          <Route exact path="/home/tarot/tarot-cards-meaningdetails/:index" element={<TarotcardMeaningDetails />} />

          <Route exact path="/home/video reading" element={<VideoReading />} />
          
          <Route exact path="/home/zodiac" element={<Zodiac />} />
          <Route exact path="/home/zodiac/weekly-zodiac-reading" element={<WeeklyZodiac />} />
          <Route exact path="/home/zodiac/western-zodiac" element={<WesternZodiac />} />
          <Route exact path="/home/zodiac/zodiac-compatibility-signs" element={<ZodiacCompat />} />
          <Route exact path="/home/zodiac/chinese-zodiac" element={<ChineseZodiac />} />
          
          <Route exact path="/CookiesPolicy" element={<CookiePolicy />} />
          <Route exact path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route exact path="/Terms&Conditions" element={<TermsConditions />} />

        </Routes>
      </Router>
    </div>
  );
};

function DailyTarotResultWithRedirect() {
  if (document.referrer !== `${window.location.origin}/home/tarot/daily-tarot`) {
    return <Navigate to="/home/tarot/daily-tarot" />;
  }
  else{return <DailyTarotResult />;}
}

export default App;