import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from './Appbar';
import Footer from './Footer';
import Preloader from './Preloader';

const ChineseZodiac = () => {

  const lang = localStorage.getItem('selectedLanguage') || 'mm';
  const dob = localStorage.getItem('baydinwebdob');

  const [data, setData] = useState([]);
  const [res, setRes] = useState()
  const [animals,setAnimals] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (dob === null) {window.location.href = "/login";}
      else{
        try {
            const year = dob.split('-')[2]; 
            const animal = animals[(year - 4) % 12];

            const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}chinesezodiac.json`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setAnimals(result.birth_animal);
            for (const key in animals) {
                if (animals[key] === animal) {
                  setRes(animal.name);
                  setData(animals[key].type);
                  setLoading(false);break; 
                }
              }
          } catch (error) {
            console.error('Error fetching data:', error);setLoading(true);
          }}
    };
    fetchData(); 
  });


 

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        data && (
          <div className="uk-card uk-card-default uk-width-1-1@m" style={{"marginTop":"70px"}}>
          <div className="uk-card-header">
              <div className="uk-grid-small" uk-grid>
              <div style={{"display": "flex","justify-content": "center","align-items": "center"}}>
                      <img  width="50%" height="50vh" src={data.img} alt={res}/>
                  </div>
                  <div className="uk-width-expand uk-text-center">
                      <h4 class="uk-card-title uk-margin-remove-bottom uk-margin-top">{res}</h4>
                  </div>
              </div>
          </div>
          <div className="uk-card-body">
          <p style={{ whiteSpace: 'pre-line' }}>{data.meaning}</p>
          </div>
          <div className="uk-card-footer">
              <button class="uk-button uk-button-text">Share</button>
          </div>
      </div>
        )
      )}
    </div>
  );
};

export default function ChineseZodiacReading() {
  return (
    <div>
      <ResponsiveAppBar />
      <ChineseZodiac />
      <Footer/>
    </div>
  );
}
