import React, { useEffect } from "react";

const AdBanner320 = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      atOptions = {
        'key': '3bf1d01d242efea44f13807a028682f2',
        'format': 'iframe',
        'height': 50,
        'width': 320,
        'params': {}
      };
    `;
    const invokeScript = document.createElement("script");
    invokeScript.type = "text/javascript";
    invokeScript.async = true;
    invokeScript.src = "//www.highperformanceformat.com/3bf1d01d242efea44f13807a028682f2/invoke.js";

    const adContainer = document.getElementById("ad-banner-container");
    if (adContainer) {
      adContainer.appendChild(script);
      adContainer.appendChild(invokeScript);
    }

    return () => {
      if (adContainer) {
        adContainer.innerHTML = "";
      }
    };
  }, []);

  return (
    <div style={{ textAlign: "center" }} id="ad-banner-container">
      {/* Ad will be dynamically injected here */}
    </div>
  );
};

export default AdBanner320;
