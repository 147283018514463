import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from './Appbar';
import Preloader from './Preloader';
import Footer from  './Footer';

const AstrologyDetails = () => {
  const lang = localStorage.getItem('selectedLanguage');
  const [res, setRes] = useState([]);
  const [data, setData] = useState([]);
  const [meaning,setMeaning] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const dob = localStorage.getItem('baydinwebdob');

    fetch('https://us-central1-nfc-project-e3961.cloudfunctions.net/function-1/getMahabote?birthday=' + dob)
      .then((response) => response.json())
      .then((res) => {
        setRes(res.result);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, []);
    
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}generalmahabote.json`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const result = await response.json();
            for (const key in result.birth) {
              if (result.birth[key].name === res) {
                setData(result.birth[key]);
                setMeaning(result.birth[key].type.meaning);
                setLoading(false);break; 
              }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
          }
        };
        fetchData();
      }, [lang,res]);

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        data && (
          <div className="uk-card uk-card-default uk-width-1-1@m" style={{ marginTop: '70px' }}>
            <div className="uk-card-header">
              <div className="uk-grid-small" uk-grid>
                <div className="uk-width-expand">
                <h4 className='uk-margin-top uk-text-bold'> General Mahabote Reading</h4>
                  <h4 class="uk-card-title uk-margin-remove-bottom uk-margin-top">{res}</h4>
                </div>
              </div>
            </div>
            <div className="uk-card-body">
            <p style={{ whiteSpace: 'pre-line' }}>{meaning}</p>
            </div>
            <div className="uk-card-footer">
              <button class="uk-button uk-button-text">Share</button>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default function YourComponent() {
  return (
    <div>
      <ResponsiveAppBar />
      <AstrologyDetails />
      <Footer/>
    </div>
  );
}
