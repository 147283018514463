import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from './Appbar'; 
import Footer from './Footer'; 
import Preloader from './Preloader';
import { Helmet, HelmetProvider } from 'react-helmet-async';


const AngelNums = () => {
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  //const lang = localStorage.getItem('selectedLanguage');
  const lang = 'mm';
  const gotoAngelNumsdetails = (index) => {
    window.location.href = "/home/angelnumbersdetails/"+index;
  };
  
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}angelnumbers.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);setLoading(true);
      }
    };
    fetchData();
  }, [lang]);
  
  
  return (
    <div>
    {isLoading ? (
      <Preloader />
    ) : (
      <Box sx={{ flexGrow: 1, margin: '6rem' }}>
      <h3 class="uk-text-bold uk-title">Angel Numbers</h3>   
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 6, sm: 6, md: 6 }}>
      {data.map((item, index) => (
        <Grid item xs={6} sm={6} md={3} key={item}>
        
        <div onClick={() => gotoAngelNumsdetails(index)} class="uk-card uk-card-hover uk-card-default uk-card-body" style={{"border-radius":"1rem"}}>
        <div className="uk-width-auto uk-float-right">
        <img style={{"borderRadius":"5%"}} width="100" height="100" src={item.img} alt={item.title}/>
        </div>
        <div className="uk-width-expand">
        <h4 class="uk-margin-remove-bottom homepghead1">{item.title}</h4>
        <p class="uk-text-meta homepgtext1">{item.about.slice(0, 100)} ...</p>
        </div>
        </div>
        </Grid>
      ))}
      </Grid>
      </Box>
    )}
    </div>
  );
};




export default function AngelNumsReading() {
  return (
    <HelmetProvider>
    <div>
    <Helmet>
    <title>Angel Numbers | Black Whispers</title>
    <meta name="description" content="Explore the mystical meanings of angel numbers and unlock their guidance in your life. Find your angel number now!" />
    <meta property="og:title" content="Angel Numbers | Black Whispers" />
    <meta property="og:description" content="Discover the spiritual significance of angel numbers. Let these divine messages guide you on your journey!" />
    <meta property="og:image" content="https://storage.googleapis.com/www.blackwhispers.com/image/maincat/maincat_angelnums.png" />
    <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
    <meta property="og:url" content="https://www.blackwhispers.com/home/angel%20numbers" />
    <meta property="og:type" content="website" />
    </Helmet>
    <ResponsiveAppBar />
    <AngelNums />
    <Footer />
    </div>
    </HelmetProvider>
  );
}
