// import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
// import { auth, signInWithGoogle } from "./firebase";
// // import { logInWithEmailAndPassword } from "./firebase";
// import { useAuthState } from "react-firebase-hooks/auth";
// import TextField from '@mui/material/TextField';
// import "./Login.css";
// import Button from '@mui/material/Button';
// import dayjs from 'dayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateField } from '@mui/x-date-pickers/DateField';
// import Preloader from './Preloader';

// function Login() {
//   const [fullname, setfullname] = useState(localStorage.getItem('fullname') || "");
//   const parsedDob = localStorage.getItem('baydinwebdob') ? localStorage.getItem('baydinwebdob').split('-') : [];
//   const [dob, setdob] = useState(parsedDob.length === 3 ? dayjs(`${parsedDob[2]}-${parsedDob[1]}-${parsedDob[0]}`) : "");

  
//   const [user, loading, error] = useAuthState(auth);
//   const navigate = useNavigate();
  
//   const [showLoginForm, setShowLoginForm] = useState(false);
  
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       if (loading) {<Preloader/>}
//       if (user) navigate("/home");setShowLoginForm(true);
//       if (error) navigate("/login");
//     }, 3000);
    
//     return () => clearTimeout(timer);
//   }, [loading, user, navigate, error]);
  
//   return (
//     <div className="login">
//     {showLoginForm && ( // Render the login form only when showLoginForm is true
//     <div className="login__container">
//     <h3>Welcome to Black Whispers</h3>
    
//     <TextField id="outlined-basic"  variant="outlined"
//     type="text"
//     value={fullname}
//     onChange={(e) => {
//       setfullname(e.target.value);
//       localStorage.setItem('fullname', e.target.value);
//     }}
//     label="Your Full Name in English"
//     />
//     <LocalizationProvider dateAdapter={AdapterDayjs}>
//     <DateField
//     id="outlined-basic"
//     variant="outlined"
//     style={{"margin-top":"10px"}}
//     label="Date of Birth" 
//     value={dob}
//     onChange={(newDate) => { 
//       setdob(newDate);
//       const parsedDate = dayjs(newDate);
//       const day = parsedDate.format('DD');
//       const month = parsedDate.format('MM');
//       const year = parsedDate.format('YYYY');
//       const formattedDate = day + "-" + month + "-" + year;
//       localStorage.setItem('baydinwebdob', formattedDate);
//     }}
//     format="DD-MM-YYYY"
//     />
//     </LocalizationProvider>
    
//     <Button variant="contained"style={{ marginTop: "10px" }}
//     onClick={() => {
//       if (fullname.length===0 || dob.length===0) {
//         alert("Please provide your full name and date of birth.");
//       } else {
//         signInWithGoogle();
//       }
//     }}
//     >
//     Login with Google
//     </Button>
    
    
//     </div>
//     )}
//     </div>
//     );
//   }
  
//   export default Login;
  

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "./Login.css";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateField } from "@mui/x-date-pickers/DateField";
import Preloader from "./Preloader";



function Login() {
  const [fullname, setFullname] = useState(localStorage.getItem("fullname") || "");
  const parsedDob = localStorage.getItem("baydinwebdob")
    ? localStorage.getItem("baydinwebdob").split("-")
    : [];
  const [dob, setDob] = useState(
    parsedDob.length === 3 ? dayjs(`${parsedDob[2]}-${parsedDob[1]}-${parsedDob[0]}`) : ""
  );

  const navigate = useNavigate();
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showPreloader, setShowPreloader] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPreloader(false);
      const storedFullname = localStorage.getItem("fullname");
      const storedDob = localStorage.getItem("baydinwebdob");

      if (!storedFullname || !storedDob) {
        setShowLoginForm(true);
      } else {
        navigate("/home");
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <div className="login">
      {showPreloader ? (
        <Preloader />
      ) : (
        showLoginForm && (
          <div className="login__container">
            <h3>Welcome to Black Whispers</h3>

            <TextField
              id="outlined-basic"
              variant="outlined"
              type="text"
              value={fullname}
              onChange={(e) => {
                setFullname(e.target.value);
                localStorage.setItem("fullname", e.target.value);
              }}
              label="Your Full Name in English"
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateField
                id="outlined-basic"
                variant="outlined"
                style={{ marginTop: "10px" }}
                label="Date of Birth"
                value={dob}
                onChange={(newDate) => {
                  setDob(newDate);
                  const parsedDate = dayjs(newDate);
                  const day = parsedDate.format("DD");
                  const month = parsedDate.format("MM");
                  const year = parsedDate.format("YYYY");
                  const formattedDate = `${day}-${month}-${year}`;
                  localStorage.setItem("baydinwebdob", formattedDate);
                }}
                format="DD-MM-YYYY"
              />
            </LocalizationProvider>

            <Button
              variant="contained"
              style={{ marginTop: "10px" }}
              onClick={() => {
                if (fullname.trim() === "" || !dob) { alert("Please provide your full name and date of birth.");}
                 else {navigate("/home");}
              }}
            >
              Login
            </Button>
          </div>
        )
      )}
    </div>
  );
}

export default Login;

