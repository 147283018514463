import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from './Appbar';
import Preloader from './Preloader';
import Footer from './Footer';


const WeeklyZodiac = () => {
  const lang = localStorage.getItem('selectedLanguage') || 'mm';
  const dob = localStorage.getItem('baydinwebdob');

  const [data, setData] = useState({});
  const [res, setRes] = useState([]);
  const [isLoading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      if (dob === null) {
        window.location.href = '/login';
      } else {
        try {
          const response = await fetch(`https://us-central1-nfc-project-e3961.cloudfunctions.net/function-1/getwesternZodiac?birthday=${dob}`);
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const result = await response.json();
          setRes(result.sign);
          setLoading(false);
        } catch (error) {
          console.error('Error fetching data:', error);
          setLoading(true);
        }
      }
    };
    fetchData();
  }, [dob]);

  useEffect(() => {
    const fetchRes = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/mmdailyzodiacsigns.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        for (const key in result.sign) {
          if (result.sign[key].name === res) { 
            setData(result.sign[key]);
            setLoading(false);
            break;
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(true);
      }
    };
    fetchRes();
  }, [lang, res]);



  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        data && (
            data && (
                <div className="uk-card uk-card-default uk-width-1-1@m" style={{ marginTop: '70px' }}>
                  <div className="uk-card-header">
                    <div className="uk-grid-small" uk-grid>
                      <div className="uk-width-expand">
                      <h4 className='uk-margin-top uk-text-bold'> Weekly Zodiac Reading</h4>
                        <h4 class="uk-card-title uk-margin-remove-bottom uk-margin-top">{data.name}</h4>
                      </div>
                    </div>
                  </div>
                  <div className="uk-card-body">
                  <p style={{ whiteSpace: 'pre-line' }}>{data.dailyres}</p>
                  </div>
                  <div className="uk-card-footer">
                    <button class="uk-button uk-button-text">Share</button>
                  </div>
                </div>
              )
        )
      )}
    </div>
  );
};

export default function WeeklyZodiacReading() {
  return (
    <div>
      <ResponsiveAppBar />
      <WeeklyZodiac />
      <Footer/>
    </div>
  );
}
