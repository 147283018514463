import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ResponsiveAppBar from './Appbar';
import Footer from './Footer'; 
import Preloader from './Preloader';
import { useParams } from 'react-router-dom';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    
    return (
        <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
            )}
            </div>
            );
        }
        
        function a11yProps(index: number) {
            return {
                id: `simple-tab-${index}`,
                'aria-controls': `simple-tabpanel-${index}`,
            };
        }
        const TarotCardMeaningDetails = () => {
            const { index } = useParams();
            const lang = localStorage.getItem('selectedLanguage');
            const [data, setData] = useState([]);
            const [isLoading, setLoading] = useState(true);
            const [value, setValue] = React.useState(0);
            
            const handleChange = (event: React.SyntheticEvent, newValue: number) => {
                setValue(newValue);
            }; 
            useEffect(() => {
                const fetchData = async () => {
                    try {
                        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}majorcards.json`);
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        const result = await response.json();
                        setData(result.tarot_deck[index]);setLoading(false);
                    } catch (error) {
                        console.error('Error fetching data:', error);setLoading(true);
                    }
                };
                fetchData();
            }, [lang,index]);
            
            
            return (
                <div>
                {isLoading ? (
                    <Preloader />
                    ) : (
                        data && (
                            <>
                            <div style={{marginTop: '70px'}} className="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin uk-grid uk-grid-stack" uk-grid="">
                            <div className="uk-card-media-left uk-cover-container uk-first-column" style={{ margin:'50px auto',width: '220px', height: '400px' }}>
                            <img style={{ width: '100%', height: '100%' }}  src={data.type.upright.img} alt={data.name} uk-cover />
                            <canvas width="600" height="400"></canvas>
                            </div>
                            <div>
                            <div className="uk-card-body">
                            <h3 class="uk-card-title">{data.name} Upright Meaning</h3>
                            <div>
                            <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="General" {...a11yProps(0)} />
                            <Tab label="Love" {...a11yProps(1)} />
                            <Tab label="Career" {...a11yProps(2)} />
                            <Tab label="Finances" {...a11yProps(3)} />
                            <Tab label="Health" {...a11yProps(4)} />
                            </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                            {data.type.upright.general}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                            {data.type.upright.love}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                            {data.type.upright.career}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                            {data.type.upright.finances}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4}>
                            {data.type.upright.health}
                            </CustomTabPanel>
                            </Box>
                            
                            </div>
                            </div>
                            </div>
                            </div>
                            
                            <div className="uk-card uk-card-default uk-grid-collapse uk-child-width-1-2@s uk-margin uk-grid uk-grid-stack" uk-grid="">
                            <div className="uk-card-media-left uk-cover-container uk-first-column" style={{ margin:'50px auto',width: '220px', height: '400px' }}>
                            <img style={{ width: '100%', height: '100%' }}  src={data.type.reversed.img} alt={data.name} uk-cover />
                            <canvas width="600" height="400"></canvas>
                            </div>
                            <div>
                            <div className="uk-card-body">
                            <h3 class="uk-card-title">{data.name} Reversed Meaning</h3>
                            <div>
                            <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="General" {...a11yProps(0)} />
                            <Tab label="Love" {...a11yProps(1)} />
                            <Tab label="Career" {...a11yProps(2)} />
                            <Tab label="Finances" {...a11yProps(3)} />
                            <Tab label="Health" {...a11yProps(4)} />
                            </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0}>
                            {data.type.reversed.general}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={1}>
                            {data.type.reversed.love}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={2}>
                            {data.type.reversed.career}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={3}>
                            {data.type.reversed.finances}
                            </CustomTabPanel>
                            <CustomTabPanel value={value} index={4}>
                            {data.type.reversed.health}
                            </CustomTabPanel>
                            </Box>
                            
                            </div>
                            </div>
                            </div>
                            </div>
                        </>
                        )
                        )}
                        </div>
                        );
                    };
                    
                    export default function TarotCardMeaningDetailsReading() {
                        return (
                            <div>
                            <ResponsiveAppBar />
                            <TarotCardMeaningDetails />
                            <Footer/>
                            </div>
                            );
                        }
                        