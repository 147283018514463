import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AdBanner468 from "./AdBanner468";
import AdBanner320 from "./AdBanner320";

const styles = {
  footer: {
    position: "absolute",
    bottom: "-150px",
    minWidth: "100%",
  },
};

const Footer = () => {
  const [showFooter, setShowFooter] = useState(false);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768);
    };

    // Add event listener for resize
    window.addEventListener("resize", handleResize);

    // Cleanup event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Delay footer display
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFooter(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []);

  // Load AdSense script dynamically
  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-5046935516889578";
    script.crossOrigin = "anonymous";
    document.body.appendChild(script);

    script.onload = () => {
      try {
        if (window.adsbygoogle) {
          window.adsbygoogle.push({});
        }
      } catch (e) {
        console.error("AdSense script failed to load or initialize", e);
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return showFooter ? (
    <>
  
     
  <div>

      {isDesktop ? <AdBanner468 /> : <AdBanner320 />}
    </div>

      <div style={styles.footer}>
        <nav className="uk-background-secondary uk-light" uk-navbar>
          <div className="uk-container">
            <div className="uk-navbar-left">
              <ul className="uk-navbar-nav">
                <li className="uk-text-bold">
                  <Link to="/CookiesPolicy">Cookies Policy</Link>
                </li>
                <li className="uk-text-bold">
                  <Link to="/PrivacyPolicy">Privacy Policy</Link>
                </li>
                <li className="uk-text-bold">
                  <Link to="/Terms&Conditions">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  ) : null;
};

export default function footer() {
  return <Footer />;
}