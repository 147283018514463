// import React, { useEffect, useState,useCallback } from "react";
import React, { useEffect, useState } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import CakeTwoToneIcon from '@mui/icons-material/CakeTwoTone';
import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import AndroidIcon from '@mui/icons-material/Android';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
// import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
// import $ from 'jquery';
// import { auth, db, logout } from "./firebase";
// import { query, collection, getDocs, where } from "firebase/firestore";

// const pages = ['Products', 'Pricing', 'Blog'];
// const settings = ['Profile', 'Logout'];
const playStoreURL = 'https://play.google.com/store/apps/details?id=com.blackwhispers.blackwhispersapp';
const facebookPageURL = 'https://www.facebook.com/thewhispersbaydin';
const emailAddress = 'salvia@blackwhispers.com';

const languages = [
    {
      value: 'mm',
      label: 'Myn',
    },
    {
      value: 'eng',
      label: 'Eng',
    }
  ];
function ResponsiveAppBar() {
  
  
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('mm');
  


  // const [user, loading] = useAuthState(auth);
  // const [name, setName] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
   const getName =  localStorage.getItem('fullname');
   const getDOB = localStorage.getItem('baydinwebdob');
   
   if (!getName && !getDOB) { 
    navigate("/login");  
    window.location.reload(); 
  }
  
    
    // if (user) fetchUserName(); 
  }, [navigate]); 


  // const fetchUserName = useCallback(async () => {
  //   try {
  //     const q = query(collection(db, "users"), where("uid", "==", user.uid));
  //     const docSnap = await getDocs(q);
  //     if (!docSnap.empty) {
  //       const data = docSnap.docs[0].data();
  //       const url = encodeURI("https://blackwhispers.xtechmm.workers.dev/savewusers/");
  //       var key = data.uid;
  //       var value = JSON.stringify({
  //         fullname: localStorage.getItem("fullname"),
  //         dob: localStorage.getItem("baydinwebdob"),name: data.name,email: data.email,authProvider: data.authProvider,ts: new Date().getTime(),
  //       });
        
  //       $.post(url, { key: key, value: value }, function (data, status) {});
  //       // setName(data.name);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [user]);

  // useEffect(() => {
  //   if (loading) return;
  //   if (!user) return navigate("/");
  //   if (user) fetchUserName(); 
  // }, [user, loading, fetchUserName, navigate]); 

  // useState(() => {
  //   if(localStorage.getItem('selectedLanguage') !== null){
  //     setSelectedLanguage(localStorage.getItem('selectedLanguage'));
  //   }else{
  //   fetch('https://ip.mmfootballgroup.com/geo/check')
  //       .then(response => response.json())
  //       .then(data => {
  //         const country = data.countryCode;
  //         if (country === 'MM') {setSelectedLanguage('mm');
  //         localStorage.setItem('selectedLanguage','mm');
  //         } else {
  //           setSelectedLanguage('eng');localStorage.setItem('selectedLanguage','eng');
  //         }
  //       })
  //       .catch(error => {console.error('Error fetching data:', error);});}
  //   }, []);


  useEffect(() => {
    const savedLanguage = localStorage.getItem("selectedLanguage");
    if (savedLanguage) {
      setSelectedLanguage(savedLanguage);
    } else {
      // fetch("https://ip.mmfootballgroup.com/geo/check")
      //   .then((response) => response.json())
      //   .then((data) => {
      //     const country = data.countryCode;
      //     const defaultLang = country === "MM" ? "mm" : "eng";
      //     setSelectedLanguage(defaultLang);
      //     localStorage.setItem("selectedLanguage", defaultLang);
      //   })
      //   .catch((error) => console.error("Error fetching data:", error));

        const country = "MM";
        const defaultLang = country === "MM" ? "mm" : "eng";
        setSelectedLanguage(defaultLang);
        localStorage.setItem("selectedLanguage", defaultLang);
    }
  }, []);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleLanguageChange = (event) => {
    setSelectedLanguage(event.target.value); // Update the selected language value
    console.log(event.target.value);
    localStorage.setItem('selectedLanguage', event.target.value);
    window.location.reload();
  };



  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

const logout = () => {
  navigate("/login")
  localStorage.removeItem('fullname');
  localStorage.removeItem('baydinwebdob');
}
  
  return (
    <ThemeProvider theme={darkTheme}>
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>   
        <a href="/home">
        <Avatar  src="https://blackwhispers.xtechmm.workers.dev/img/logo.png" sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
        </a>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/home"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'none', 
                color: 'white',
              },
            }}
          >
             Black Whispers
          </Typography>

          
          <a href="/home">
          <Avatar src="https://blackwhispers.xtechmm.workers.dev/img/logo.png" sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          </a>
      
          
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}></Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" /> */}
               <AccountCircleRoundedIcon sx={{fontSize: '2.5rem'}}/>
                {/* <div style={{fontSize:"1rem"}}>{user?.displayName}</div> */}
                
              </IconButton>
              
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
        
          
        <MenuItem uk-toggle="target: #offcanvas-slide" onClick={handleCloseUserMenu}>Profile</MenuItem>
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Box>
          <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '9ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <TextField
          id="outlined-select-currency"
          select
          label=""
          value={selectedLanguage} // Set the value attribute with the selected language value
          onChange={handleLanguageChange} // Handle the language change event
          helperText=""
        >
          {languages.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        </div>
        </Box>
       
        <div id="offcanvas-slide" uk-offcanvas="overlay: true; flip: true;"> 
    <div className="uk-offcanvas-bar">

       
        <button style={{cursor:"pointer"}} class="uk-offcanvas-close uk-hover" type="button" uk-close><span uk-icon="icon: close"></span></button>

        <h4 class="uk-text-center">Black Whispers Baydin</h4>
        <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <AccountBoxTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary={localStorage.getItem("fullname")} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <CakeTwoToneIcon />
              </ListItemIcon>
              <ListItemText primary={localStorage.getItem("baydinwebdob")} />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
      <Divider />
        <nav aria-label="secondary mailbox folders">
        <ListItem disablePadding>
        <a href={playStoreURL} target="_blank" rel="noopener noreferrer">
          <ListItemButton>
            <ListItemIcon>
              <AndroidIcon />
            </ListItemIcon>
            <ListItemText primary="Download on Playstore" />
          </ListItemButton>
        </a>
        </ListItem>
      <ListItem disablePadding>
        <a href={facebookPageURL} target="_blank" rel="noopener noreferrer">
          <ListItemButton>
            <ListItemIcon>
              <FacebookOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Our Page" />
          </ListItemButton>
        </a>
      </ListItem>
      <ListItem disablePadding>
      <a href={`mailto:${emailAddress}`}>
          <ListItemButton>
            <ListItemIcon>
              <AttachEmailOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="Contact Us" />
          </ListItemButton>
        </a>
      </ListItem>
    </nav>
</Box>
    </div>
</div>

        </Toolbar>
      </Container>
    </AppBar>
    </ThemeProvider>
  );
}


const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#1976d2',
      },
    },
  });

export default ResponsiveAppBar;


