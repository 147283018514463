import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import ResponsiveAppBar from './Appbar';
import Preloader from './Preloader';
import { useParams } from 'react-router-dom';
import Footer from  './Footer';
const MonthlySpecialDetails = () => {

  const { index } = useParams();
  //const lang = localStorage.getItem('selectedLanguage');
  const lang = "mm";
  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}monthlyspecial.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result[index]);setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);setLoading(true);
      }
    };
    fetchData();
  }, [lang,index]);
 

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        data && (
          <div className="uk-card uk-card-default uk-width-1-1@m" style={{"marginTop":"70px"}}>
          <div className="uk-card-header">
              <div className="uk-grid-small" uk-grid>
                  <div style={{"display": "flex","justify-content": "center","align-items": "center"}}>
                      <img  width="50%" height="50vh" src={data.img} alt={data.title}/>
                  </div>
                  <div className="uk-width-expand uk-text-center">
                      <h4 class="uk-card-title uk-margin-remove-bottom uk-margin-top">{data.title}</h4>
                  </div>
              </div>
          </div>
          <div className="uk-card-body">
              <Typography dangerouslySetInnerHTML={{ __html: data.about.replace(/\n/g, '<br>') }} />
          </div>
          <div className="uk-card-footer">
              <button class="uk-button uk-button-text">Share</button>
          </div>
      </div>
        )
      )}
    </div>
  );
};

export default function MonthlySpecialDetailsReading() {
  return (
    <div>
      <ResponsiveAppBar />
      <MonthlySpecialDetails />
      <Footer />
    </div>
  );
}
