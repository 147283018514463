import React, { useEffect } from "react";

const AdBanner468 = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
      atOptions = {
        'key' : '245292704021981d33cd875093ccd526',
		'format' : 'iframe',
		'height' : 60,
		'width' : 468,
		'params' : {}
      };
    `;
    const invokeScript = document.createElement("script");
    invokeScript.type = "text/javascript";
    invokeScript.async = true;
    invokeScript.src = "//www.highperformanceformat.com/245292704021981d33cd875093ccd526/invoke.js";
    
    const adContainer = document.getElementById("ad-banner-container");
    if (adContainer) {
      adContainer.appendChild(script);
      adContainer.appendChild(invokeScript);
    }
    

    return () => {
      if (adContainer) {
        adContainer.innerHTML = "";
      }
    };
  }, []);
  
  return (
    <div style={{ textAlign: "center" }} id="ad-banner-container">
    {/* Ad will be dynamically injected here */}
    </div>
  );
};

export default AdBanner468;
