import React, { useState, useEffect } from 'react';
import ResponsiveAppBar from './Appbar';
import Preloader from './Preloader';
import Footer from  './Footer';

const Personality = () => {
  const lang = localStorage.getItem('selectedLanguage') || 'mm';

 
  
  const [data, setData] = useState([]);
  const [res,setRes] = useState([]);
  const [type,setType] = useState([]);
  const [isLoading, setLoading] = useState(true);

  
  useEffect(() => {
    const getfullname =  localStorage.getItem('fullname');
    const fullname = getfullname.replace(/\s/g, '');
    if (!getfullname || getfullname.trim() === '') {
      window.location.href = "/login"; 
     
    }
  
    else{
    fetch('https://us-central1-nfc-project-e3961.cloudfunctions.net/function-1/getnumPersona?name='+fullname)
      .then((response) => response.json())
      .then((result) => {
        const persona = result.personalityNum;
        setRes(persona.toString());setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });}
  }, []);

  useEffect(() => {
    const fetchRes = async () => {
      try {
        const response = await fetch(`https://blackwhispers.xtechmm.workers.dev/js/${lang}numerology_personality.json`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        for (const key in result.name) {
          if (result.name[key].number === res) {
            setData(result.name[key].number);
            setType(result.name[key].type.personality);   
            setLoading(false);break; 
         
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);setLoading(true);
      }
    };
    fetchRes();
  }, [lang,res]);
 

  return (
    <div>
      {isLoading ? (
        <Preloader />
      ) : (
        data && (
          <div className="uk-card uk-card-default uk-width-1-1@m" style={{"marginTop":"70px"}}>
          <div className="uk-card-header">
              <div className="uk-grid-small" uk-grid>
                  
                  <div className="uk-width-expand uk-text-center">
                      <h4 class="uk-card-title uk-margin-remove-bottom uk-margin-top">Your Personality Number Is {data}</h4>
                  </div>
              </div>
          </div>
          <div className="uk-card-body">
          <p style={{ whiteSpace: 'pre-line' }}>{type}</p>
          </div>
          <div className="uk-card-footer">
              <button class="uk-button uk-button-text">Share</button>
          </div>
      </div>
        )
      )}
    </div>
  );
};

export default function PersonalityReading() {
  return (
    <div>
      <ResponsiveAppBar />
      <Personality />
      <Footer/>
    </div>
  );
}
